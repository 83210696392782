import React from "react";
import styled from "styled-components";
// import { Helmet } from "react-helmet";
import { Link, graphql, useStaticQuery } from "gatsby";
import {
  IoIosTimer,
  IoIosFlash,
  IoIosCash,
  IoIosHeart,
  IoIosHappy,
  IoIosArrowForward,
  IoMdOpen,
} from "react-icons/io";

import { useModal } from "../components/useModal";

import Image from "../components/image";
import {
  Button,
  Container,
  Wrapper,
  Row,
  Box,
  FlexButtons,
} from "../components/util";
import {
  IframeContainer,
  ResourceCard,
  CustomerQuote,
  CustomerQuoteLarge,
  Modal,
  CallToAction,
} from "../components/site";
import Layout from "../components/layout";
import Seo from "../components/seo";

import PayHeroLogo from "../images/logos/PayHero/PayHero_Full.svg";
import XeroLogo_Single from "../images/logos/Xero/Xero_Blue_Single.svg";
import InvoxyLogo from "../images/logos/Invoxy/Invoxy_Full.svg";
import DroppahLogo from "../images/logos/Droppah/Droppah_Full_Black.svg";

const TextCallout = styled.div`
  .gatsby-image-wrapper {
    margin-left: auto;
  }
`;

const CardRow = styled.div`
  a {
    width: 100%;
  }
`;

const FlexiTimeProducts = styled(Row)`
  > div {
    padding: 30px;
    text-align: center;
    border-radius: ${(props) => props.theme.styles.borderRadius};
    border: solid 1px ${(props) => props.theme.colours.greyDarken};

    img {
      margin-bottom: 30px;
      margin-top: 30px;
      max-width: 200px;
      width: 85%;
      max-height: 35px;
    }

    h2 {
      margin: 10px 0;
    }
  }
`;

const ICNZB = (props) => {
  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();

  const contentfulData = useStaticQuery(graphql`
    query {
      allContentfulResource(
        sort: { order: DESC, fields: publishedDate }
        filter: {
          resourceTitle: {
            in: ["Understanding New Zealand’s payroll compliance nightmare"]
          }
        }
      ) {
        edges {
          node {
            publishedDate
            resourceImage {
              gatsbyImageData(width: 650, layout: FIXED, quality: 100)
            }
            resourceTitle
            resourceType
            resourceTopic
            resourceContent {
              raw
            }
            excerpt
            url
            author
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Seo
        title="ICNZB & PayHero | PayHero"
        pathname={props.location.pathname}
      />
      <Container>
        <Wrapper stackGap={80} noPaddingBottom>
          <Box stackGap={30}>
            <img
              className="-center"
              src={PayHeroLogo}
              alt="PayHero | Compliant, Accurate & Easy to Use Payroll Software"
              css={{ width: "40%", maxWidth: "220px", maxHeight: "40px" }}
            />
            <h1 className="-textCenter -fontLarge">
              Payroll you and your <br className="hideOnMobile" />
              clients can trust
            </h1>
            <FlexButtons justify="center">
              <Button
                className="primary -lg gtm-cta"
                onClick={toggleModal}
                atag
              >
                Request a Call Back
              </Button>
              <Button
                className="grey -lg"
                to="https://partners.flexitime.works"
                atag
                externalLink
              >
                Become a Partner <IoMdOpen css={{ top: "2px" }} />
              </Button>
            </FlexButtons>
          </Box>
          <CustomerQuote
            quote="PayHero continues to evolve to keep up with the times, making it a user-friendly and up-to-date software. Their support is super efficient with fast service and support guides that are easy to refer to and understand."
            name="Pearl Falwasser"
            company="Accounts Dept"
            pic="AccountDept_Circle.png"
            center
            large
          />
          <hr />
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <Row stackGap={80} alignCenter>
            <Box size={68} stackGap={40}>
              <div className="font-bump">
                <h2>Don't miss out on the payroll opportunity</h2>
                <p>
                  Payroll compliance is a hot topic with widespread reporting of
                  Holidays Act calculation issues and remediation payments.
                  According to MBIE, New Zealand workers are owed more than $2
                  billion in lost holiday pay, affecting up to one third of all
                  employees in the country.
                </p>
                <p>
                  Right now, payroll is a huge opportunity for savvy
                  bookkeepers.
                </p>
                <p>
                  One of the most common problems is using payroll systems that
                  accrue annual leave based on the hours or days an employee
                  works.
                </p>
                <p>
                  Following the very latest Holidays Act guidance from MBIE,
                  PayHero stores employee leave balances in weeks to make it
                  easier for companies with staff who work variable hours or
                  have changing work patterns to get their leave entitlements
                  correct.
                </p>
              </div>
              <Button className="greyBlue -lg" to={process.env.GATSBY_SITE_URL + '/compliance'}>
                Payroll Compliance in PayHero
              </Button>
              <TextCallout className="hideOnMobile">
                <Image
                  filename="ReadMoreImage.png"
                  alt="Read More About Payroll Compliance"
                  maxWidth={200}
                />
              </TextCallout>
            </Box>
            <Box size={32}>
              <CardRow>
                {contentfulData.allContentfulResource.edges.map((edge, i) => {
                  return (
                    <ResourceCard
                      isFeatured={false}
                      key={i}
                      image={edge.node.resourceImage.gatsbyImageData}
                      type={edge.node.resourceType}
                      topic={edge.node.resourceTopic}
                      title={edge.node.resourceTitle}
                      excerpt={edge.node.excerpt}
                      date={edge.node.publishedDate}
                      url={edge.node.url}
                      words={edge.node.resourceContent.raw}
                      show={true}
                    />
                  );
                })}
              </CardRow>
            </Box>
          </Row>
          <CustomerQuoteLarge
            quote="Payroll issues are something we see every single day. Most business owners believe that their payroll is compliant and its not a problem, but unfortunately what we see is that a lot (maybe even most) are not. 3rd Arm Admin are proud to be a FlexiTime Partner and absolutely love their approach to payroll compliance."
            name="Sue Inkersell"
            company="3rd Arm Admin"
            hero="SquadFrom3rdArmAdmin.jpg"
            pic="Sue_Circle.png"
            logo="3rdArmAdmin_Logo.png"
            link="https://www.3rdarmadmin.co.nz/"
          />
        </Wrapper>
      </Container>
      <CallToAction
        title="Take PayHero for a test flight"
        description="Get a feel for PayHero with our free & fully interactive online demo."
        showDemoButton
        hideTrialButton
      />
      <Container bg="lightGrey">
        <Wrapper>
          <Row className="font-bump" stackGap={60} alignCenter>
            <Box size={40}>
              <h2>Pay employees right with PayHero</h2>
              <p>
                PayHero is perfect for Kiwi companies that want stress-free and
                compliant online payroll. Especially those with part time and
                mobile workforces, who face unique challenges tracking employee
                time accurately and calculating the right holiday entitlements
                for their staff.
              </p>
              <p>
                Better yet, the key elements that feed into payroll are all
                included - employee leave management; fully automated payday
                filing; expense claims and reimbursements; automatic calculation
                of public holiday entitlements; rest and meal break management;
                and deep integration with Xero.
              </p>
            </Box>
            <Box size={60}>
              <IframeContainer
                src="https://player.vimeo.com/video/369453779"
                title="Introducing PayHero | Online Payroll Made Super Easy"
              />
            </Box>
          </Row>
          <CustomerQuote
            quote="PayHero has been amazing for my manufacturing client – the time clock has helped us save money by accurately recording the hours employees actually work. The documents and notes section has proved to be very valuable as now all HR docs are stored with the payroll information. It's really simple to use and looks great."
            name="Haylee Wrenn"
            company="Accountabill"
            pic="PartnerCircle_Haylee.png"
            large
            center
          />
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <h2 className="-textCenter">
            The payroll partner you've been looking for
          </h2>
          <Box className="-textCenter" stackGap={40}>
            <Row stackGap={40}>
              <Box size={33}>
                <h3>
                  <IoIosTimer />
                  <br />
                  Time tools for any business
                </h3>
                <p>
                  PayHero streamlines the employee timesheet process with
                  online, mobile, GPS or clock in/out options delivering
                  accurate employee hours straight into payroll.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoIosFlash />
                  <br />
                  Put your payroll on AutoPilot
                </h3>
                <p>
                  You can set your pays to run automatically. We’ll send the
                  payroll admin or business owner an email with all the details
                  and they can finalise the pay without having to login.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoIosCash />
                  <br />
                  Easy payments
                </h3>
                <p>
                  When you finalise a pay, transfer the funds with a Pay Now
                  POLi payment, or use our industry standard bank batch file,
                  leaving your clients in complete control of their finances.
                </p>
              </Box>
            </Row>
            <Row stackGap={40}>
              <Box size={33}>
                <h3>
                  <IoIosHeart />
                  <br />
                  Free payroll & partner benefits
                </h3>
                <p>
                  Bookkeepers that partner with PayHero get a free payroll
                  account for their business and preferential pricing for
                  clients.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <img
                    className="-center"
                    src={XeroLogo_Single}
                    alt="Xero Integration | PayHero"
                    height={40}
                    css={{ marginBottom: "10px" }}
                  />
                  Deep Xero integration
                </h3>
                <p>
                  Connect to Xero and automatically assign wage costs to
                  different account codes or tracking categories.
                </p>
              </Box>
              <Box size={33}>
                <h3>
                  <IoIosHappy />
                  <br />
                  Super support
                </h3>
                <p>
                  Our friendly local support team knows their stuff and partners
                  get priority support. With over ten years of NZ payroll
                  experience, we’ll help you with any curly questions.
                </p>
              </Box>
            </Row>
          </Box>
          <Image
            filename="PayHeroHero_Full.png"
            alt="PayHero Product Suite"
            maxWidth={1000}
            centerImage
            addShadow
          />
          <CustomerQuote
            quote="The only software on the market currently able to accurately calculate the leave entitlements for employees with irregular working patterns, without manual intervention, is PayHero."
            name="Irene Bennetts"
            company="Admin Army"
            pic="Irene_Circle.png"
            large
            center
          />
          <hr />
          <Row alignCenter stackGap={60}>
            <Box size={50} stackGap={40}>
              <div>
                <h4 className="h2">
                  Help your clients grow with software that supports their team
                  <br />
                  <span className="-fontLight">
                    FlexiTime builds software that simplifies employment. We
                    offer a range of integrated solutions to help businesses
                    manage and pay their employees.
                  </span>
                </h4>
              </div>
              <TextCallout className="hideOnMobile">
                <Image
                  filename="OurOtherProductsImage.png"
                  alt="FlexiTime Products"
                  maxWidth={300}
                />
              </TextCallout>
            </Box>
            <Box size={50}>
              <FlexiTimeProducts stackGap={30}>
                <Box size={50} stackGap={40} className="droppah-primary">
                  <div>
                    <span className="-fontBold">Scheduling</span>
                    <img
                      className="-center"
                      src={DroppahLogo}
                      alt="Droppah | Scheduling, timesheet & attendance software"
                    />
                    <h4>
                      Roster your staff and track time and attendance to control
                      costs & optimise staff coverage.
                    </h4>
                  </div>
                  <a
                    href="https://www.droppah.com"
                    target="_blank"
                    className="link-arrow -center"
                  >
                    Discover Droppah <IoMdOpen />
                  </a>
                </Box>
                <Box size={50} stackGap={40} className="invoxy-primary">
                  <div>
                    <span className="-fontBold">Billing</span>
                    <img
                      className="-center"
                      src={InvoxyLogo}
                      alt="Karmly | Contractor Management software"
                    />
                    <h4>
                      Increase billing accuracy and get paid faster with
                      timesheets, approvals & batch invoicing.
                    </h4>
                  </div>
                  <a
                    href="https://www.karmly.com/recruitment"
                    target="_blank"
                    className="link-arrow -center"
                  >
                    Discover Karmly <IoMdOpen />
                  </a>
                </Box>
              </FlexiTimeProducts>
            </Box>
          </Row>
        </Wrapper>
      </Container>
      <Container bg="Secondary" className="-textCenter">
        <Wrapper stackGap={80}>
          <Box stackGap={40}>
            <h2 css={{ color: "#fff" }}>
              Join thousands of Kiwi businesses doing payroll better
              <br />
              <span className="-fontLight">
                Find out more about PayHero or our partner programme.
              </span>
            </h2>
            <FlexButtons justify="center">
              <Button
                className="primary -lg gtm-cta"
                onClick={toggleModal}
                atag
              >
                Request a Call Back
              </Button>
              <Button
                className="grey -lg"
                to="https://partners.flexitime.works"
                atag
                externalLink
              >
                Become a Partner <IoMdOpen css={{ top: "2px" }} />
              </Button>
            </FlexButtons>
          </Box>
        </Wrapper>
      </Container>
      <Modal
        isActive={itemModalOpen}
        embedURL="https://calendly.com/d/ynv-22p-2r5"
        embedTitle="Book a Sales Call | PayHero Sales"
        handleClose={() => setItemModalOpen(false)}
      />
    </Layout>
  );
};

export default ICNZB;
